var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:[
    'left-sidebar',
    { 'left-sidebar--min': _vm.isMenuMinimize },
    { 'left-sidebar--show': _vm.isOpenMenu },
  ]},[_c('header',[_c('router-link',{class:['logo', { 'logo--min': _vm.isMenuMinimize }],attrs:{"to":{ name: 'monitoring' }}},[(_vm.isSettingsSuccess && _vm.settings.show)?[_c('img',{class:[
            'logo__img-big',
            { 'logo__img-big--hide': _vm.isMenuMinimize },
          ],attrs:{"src":require("@/assets/img/logo.svg"),"width":"70%","alt":"Ovision"}}),_c('img',{class:[
            'logo__img-small',
            { 'logo__img-small--hide': !_vm.isMenuMinimize },
          ],attrs:{"src":require("@/assets/img/logo-min.svg"),"alt":"Ovision"}})]:_vm._e(),(_vm.isSettingsSuccess && !_vm.settings.show)?[_c('img',{class:[
            'logo__img-big',
            { 'logo__img-big--hide': _vm.isMenuMinimize },
          ],attrs:{"src":require("@/assets/img/logo-youmay.svg"),"width":"70%","alt":"Youmay"}}),_c('img',{class:[
            'logo__img-small',
            { 'logo__img-small--hide': !_vm.isMenuMinimize },
          ],attrs:{"src":require("@/assets/img/logo-youmay-min.svg"),"width":"70%","alt":"Youmay"}})]:_vm._e()],2)],1),_c('ul',{staticClass:"nav flex-column"},[(
        _vm.$auth.user().type_user == 'admin' || _vm.$auth.user().type_user == 'guard'
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"exact":"","to":{ name: 'monitoring' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-view-dashboard")]),_c('span',{class:[
            'left-sidebar__menu-title',
            { 'left-sidebar__menu-title--hide': _vm.isMenuMinimize },
          ]},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.monitoring"))+" ")])],1)],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'users' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-account-multiple")]),_c('span',{class:[
            'left-sidebar__menu-title',
            { 'left-sidebar__menu-title--hide': _vm.isMenuMinimize },
          ]},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.users"))+" ")])],1)],1),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().roles.includes('journal')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'journal' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-notebook")]),_c('span',{class:[
            'left-sidebar__menu-title',
            { 'left-sidebar__menu-title--hide': _vm.isMenuMinimize },
          ]},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.journal"))+" ")])],1)],1):_vm._e(),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().type_user == 'manager' ||
        _vm.$auth.user().roles.includes('reqview')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'requests' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-lock-check")]),_c('span',{class:[
            'left-sidebar__menu-title',
            { 'left-sidebar__menu-title--hide': _vm.isMenuMinimize },
          ]},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.requests"))+" ")])],1)],1):_vm._e(),(_vm.$auth.user().type_user == 'admin')?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'guards' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-account-star")]),_c('span',{class:[
            'left-sidebar__menu-title',
            { 'left-sidebar__menu-title--hide': _vm.isMenuMinimize },
          ]},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.guards"))+" ")])],1)],1):_vm._e(),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().type_user == 'guard' ||
        _vm.$auth.user().roles.includes('devices')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'devices' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-cctv")]),_c('span',{class:[
            'left-sidebar__menu-title',
            { 'left-sidebar__menu-title--hide': _vm.isMenuMinimize },
          ]},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.devices"))+" ")])],1)],1):_vm._e(),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().roles.includes('adminedit')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'settings' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-cog")]),_c('span',{class:[
            'left-sidebar__menu-title',
            { 'left-sidebar__menu-title--hide': _vm.isMenuMinimize },
          ]},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.settings"))+" ")])],1)],1):_vm._e()]),_c('v-spacer'),_c('aside',[(!_vm.isMenuMinimize)?_c('ul',[(
          _vm.$auth.user().type_user == 'admin' &&
          !isNaN(_vm.settings.sms_balance) &&
          _vm.settings.sms_balance !== ''
        )?_c('li',{staticClass:"mb-3"},[_c('a',{staticClass:"mdi mdi-cellphone-message",attrs:{"href":_vm.settings.sms_cabinet,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.balanceSMS"))+": "+_vm._s(_vm.formatCurrency(_vm.settings.sms_balance, _vm.settings.sms_currency))+" ")])]):_vm._e(),(_vm.settings.lang === 'ru')?_c('li',[_c('a',{staticClass:"mdi mdi-open-in-new",attrs:{"href":"/static/ogatecontrol.pdf","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.manual")))])]):_vm._e(),(_vm.settings.lang === 'ru')?_c('li',[_vm._v(" "+_vm._s(_vm.$ml.get("menu.support"))+":"),_c('br'),_vm._v(_vm._s(_vm.$ml.get("menu.supportPhone"))+" ")]):_vm._e(),(_vm.settings.version)?_c('li',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.version"))+": "+_vm._s(_vm.settings.version)+" ")]):_vm._e()]):_vm._e(),(!_vm.isMenuMinimize)?_c('PWAInstall'):_vm._e(),_c('button',{staticClass:"left-sidebar__hide-menu-btn",attrs:{"type":"button"},on:{"click":_vm.toggleMenuSize}},[(!_vm.isMenuMinimize)?_c('v-icon',[_vm._v("mdi-chevron-left")]):_c('v-icon',[_vm._v("mdi-chevron-right")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.hideMenu"))+" ")]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }