<template>
  <div>
    <LeftMenu />
    <TopMenu />
  </div>
</template>

<script>
import TopMenu from "../navigation/TopMenu";
import LeftMenu from "../navigation/LeftMenu";

export default {
  name: "AppMain",
  data() {
    return {
      user: {
        name: "",
        userId: null,
      },
    };
  },
  components: { TopMenu, LeftMenu },
  methods: {},
};
</script>

<style scoped></style>
