export default {
  methods: {
    getCurrencyByCode(code) {
      switch (code) {
        case "RUR":
          return "₽";
        case "USD":
          return "$";
        default:
          return "";
      }
    },

    formatCurrency(value, code) {
      switch (code) {
        case "RUR":
          return `${value} ${this.getCurrencyByCode(code)}`;
        case "USD":
          return `${this.getCurrencyByCode(code)}${value}`;
        default:
          return `${value} ${this.getCurrencyByCode(code)}`;
      }
    },
  },
};
