<template>
  <nav
    :class="[
      'left-sidebar',
      { 'left-sidebar--min': isMenuMinimize },
      { 'left-sidebar--show': isOpenMenu },
    ]"
  >
    <header>
      <router-link
        :to="{ name: 'monitoring' }"
        :class="['logo', { 'logo--min': isMenuMinimize }]"
      >
        <template v-if="isSettingsSuccess && settings.show">
          <img
            src="~@/assets/img/logo.svg"
            width="70%"
            alt="Ovision"
            :class="[
              'logo__img-big',
              { 'logo__img-big--hide': isMenuMinimize },
            ]"
          />
          <img
            src="~@/assets/img/logo-min.svg"
            alt="Ovision"
            :class="[
              'logo__img-small',
              { 'logo__img-small--hide': !isMenuMinimize },
            ]"
          />
        </template>
        <template v-if="isSettingsSuccess && !settings.show">
          <img
            src="~@/assets/img/logo-youmay.svg"
            width="70%"
            alt="Youmay"
            :class="[
              'logo__img-big',
              { 'logo__img-big--hide': isMenuMinimize },
            ]"
          />
          <img
            src="~@/assets/img/logo-youmay-min.svg"
            width="70%"
            alt="Youmay"
            :class="[
              'logo__img-small',
              { 'logo__img-small--hide': !isMenuMinimize },
            ]"
          />
        </template>
      </router-link>
    </header>
    <ul class="nav flex-column">
      <li
        class="nav-item"
        v-if="
          $auth.user().type_user == 'admin' || $auth.user().type_user == 'guard'
        "
      >
        <router-link
          class="nav-link"
          exact
          :to="{ name: 'monitoring' }"
          active-class="active"
        >
          <v-icon>mdi-view-dashboard</v-icon>
          <span
            :class="[
              'left-sidebar__menu-title',
              { 'left-sidebar__menu-title--hide': isMenuMinimize },
            ]"
          >
            {{ $ml.get("menu.monitoring") }}
          </span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'users' }"
          active-class="active"
        >
          <v-icon>mdi-account-multiple</v-icon>
          <span
            :class="[
              'left-sidebar__menu-title',
              { 'left-sidebar__menu-title--hide': isMenuMinimize },
            ]"
          >
            {{ $ml.get("menu.users") }}
          </span>
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().roles.includes('journal')
        "
      >
        <router-link
          class="nav-link"
          :to="{ name: 'journal' }"
          active-class="active"
        >
          <v-icon>mdi-notebook</v-icon>
          <span
            :class="[
              'left-sidebar__menu-title',
              { 'left-sidebar__menu-title--hide': isMenuMinimize },
            ]"
          >
            {{ $ml.get("menu.journal") }}
          </span>
        </router-link>
      </li>
      <li
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().type_user == 'manager' ||
          $auth.user().roles.includes('reqview')
        "
        class="nav-item"
      >
        <router-link
          class="nav-link"
          :to="{ name: 'requests' }"
          active-class="active"
        >
          <v-icon>mdi-lock-check</v-icon>
          <span
            :class="[
              'left-sidebar__menu-title',
              { 'left-sidebar__menu-title--hide': isMenuMinimize },
            ]"
          >
            {{ $ml.get("menu.requests") }}
          </span>
        </router-link>
      </li>
      <!-- <li
        v-if="($auth.user().type_user=='admin' || $auth.user().roles.includes('schedule'))"
        class="nav-item"
      >
        <router-link
          class="nav-link"
          :to="{ name: 'schedules' }"
          active-class="active"
        >{{ $ml.get('menu.schedules') }}</router-link>
      </li> -->

      <li v-if="$auth.user().type_user == 'admin'" class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'guards' }"
          active-class="active"
        >
          <v-icon>mdi-account-star</v-icon>
          <span
            :class="[
              'left-sidebar__menu-title',
              { 'left-sidebar__menu-title--hide': isMenuMinimize },
            ]"
          >
            {{ $ml.get("menu.guards") }}
          </span>
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().type_user == 'guard' ||
          $auth.user().roles.includes('devices')
        "
      >
        <router-link
          class="nav-link"
          :to="{ name: 'devices' }"
          active-class="active"
        >
          <v-icon>mdi-cctv</v-icon>
          <span
            :class="[
              'left-sidebar__menu-title',
              { 'left-sidebar__menu-title--hide': isMenuMinimize },
            ]"
          >
            {{ $ml.get("menu.devices") }}
          </span>
        </router-link>
      </li>
      <li
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().roles.includes('adminedit')
        "
        class="nav-item"
      >
        <router-link
          class="nav-link"
          :to="{ name: 'settings' }"
          active-class="active"
        >
          <v-icon>mdi-cog</v-icon>
          <span
            :class="[
              'left-sidebar__menu-title',
              { 'left-sidebar__menu-title--hide': isMenuMinimize },
            ]"
          >
            {{ $ml.get("menu.settings") }}
          </span>
        </router-link>
      </li>
    </ul>

    <v-spacer />

    <aside>
      <ul v-if="!isMenuMinimize">
        <li
          v-if="
            $auth.user().type_user == 'admin' &&
            !isNaN(settings.sms_balance) &&
            settings.sms_balance !== ''
          "
          class="mb-3"
        >
          <a
            class="mdi mdi-cellphone-message"
            :href="settings.sms_cabinet"
            target="_blank"
          >
            {{ $ml.get("menu.balanceSMS") }}:
            {{ formatCurrency(settings.sms_balance, settings.sms_currency) }}
          </a>
        </li>
        <li v-if="settings.lang === 'ru'">
          <a
            class="mdi mdi-open-in-new"
            href="/static/ogatecontrol.pdf"
            target="_blank"
          >
            {{ $ml.get("menu.manual") }}</a
          >
        </li>
        <li v-if="settings.lang === 'ru'">
          {{ $ml.get("menu.support") }}:<br />{{ $ml.get("menu.supportPhone") }}
        </li>

        <li v-if="settings.version" class="mt-2">
          {{ $ml.get("menu.version") }}: {{ settings.version }}
        </li>
      </ul>

      <PWAInstall v-if="!isMenuMinimize" />

      <button
        type="button"
        class="left-sidebar__hide-menu-btn"
        @click="toggleMenuSize"
      >
        <v-icon v-if="!isMenuMinimize">mdi-chevron-left</v-icon>
        <v-icon v-else>mdi-chevron-right</v-icon>
        <template v-if="!isMenuMinimize">
          {{ $ml.get("menu.hideMenu") }}
        </template>
      </button>
    </aside>
  </nav>
</template>

<script>
import PWAInstall from "@/components/PWAInstall";
import { mapState } from "vuex";
import currency from "@/mixins/currency";

export default {
  name: "LeftMenu",

  mixins: [currency],

  components: { PWAInstall },

  props: {
    isMenuMinimize: {
      type: Boolean,
      default: false,
    },

    isOpenMenu: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggleMenuSize() {
      this.$emit("toggleMenuSize");
    },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
      isSettingsSuccess: (state) => state.settings.isSuccess,
    }),
  },
};
</script>
